// BottomToolbar.js
import React from "react";
import "./BottomToolbar.css";
import CompassIcon from "./compass.png";
import SearchIcon from "./search.png";
import CameraIcon from "./camera.png";
import ProfileIcon from "./profile.png";
import CompassIconDark from "./compass-dark.png";
import SearchIconDark from "./search-dark.png";
import CameraIconDark from "./camera-dark.png";
import ProfileIconDark from "./profile-dark.png";
import { useNavigate } from "react-router-dom";

const BottomToolbar = ({
  toggleBottomToolbar,
  show,
  active,
  user,
  dark,
  showLoginModal,
}) => {
  const navigate = useNavigate();

  const goToProfile = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (user) {
      // Set lastURL in loaclStorage to the ccurrent URL
      await localStorage.setItem("lastURL", window.location.pathname);

      navigate(`/u/${user._id}`);
    } else {
      showLoginModal(true);
    }
  };

  const goToDiscover = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    await localStorage.setItem("lastURL", window.location.pathname);

    const lastDiscover = await localStorage.getItem("lastDiscover");
    await navigate(lastDiscover ? lastDiscover : "/discover");
  };

  return (
    <div
      className={`bottom-toolbar${show ? " show" : ""}${dark ? " dark" : ""}`}
    >
      <div className={`bottom-toolbar-item`} onClick={goToDiscover}>
        <div className={`bottom-toolbar-icon-container`}>
          {dark ? (
            <img
              src={CompassIcon}
              className={`bottom-toolbar-icon ${
                active == "discover" ? " active" : ""
              }`}
              alt="Discover"
            />
          ) : (
            <img
              src={CompassIconDark}
              className={`bottom-toolbar-icon ${
                active == "discover" ? " active" : ""
              }`}
              alt="Discover"
            />
          )}
        </div>
        <div
          className={`bottom-toolbar-item-text ${
            active == "discover" ? " active" : ""
          }
          ${dark ? " dark" : ""}

          `}
        >
          Discover
        </div>
      </div>

      {/* <div className={`bottom-toolbar-item`} onClick={toggleBottomToolbar}>
        <div className={`bottom-toolbar-icon-container`}>
          {dark ? (
            <img
              src={SearchIcon}
              className={`bottom-toolbar-icon ${
                active == "search" ? " active" : ""
              }`}
              alt="Search"
            />
          ) : (
            <img
              src={SearchIconDark}
              className={`bottom-toolbar-icon ${
                active == "search" ? " active" : ""
              }`}
              alt="Search"
            />
          )}
        </div>{" "}
        <div
          className={`bottom-toolbar-item-text ${
            active == "search" ? " active" : ""
          }
          ${dark ? " dark" : ""}
          `}
        >
          Search
        </div>
      </div>
      <div className={`bottom-toolbar-item`} onClick={toggleBottomToolbar}>
        <div className={`bottom-toolbar-icon-container`}>
          {dark ? (
            <img
              src={CameraIcon}
              className={`bottom-toolbar-icon ${
                active == "Create" ? " active" : ""
              }`}
              alt="Create"
            />
          ) : (
            <img
              src={CameraIconDark}
              className={`bottom-toolbar-icon ${
                active == "Create" ? " active" : ""
              }`}
              alt="Create"
            />
          )}
        </div>{" "}
        <div
          className={`bottom-toolbar-item-text ${
            active == "create" ? " active" : ""
          }
          ${dark ? " dark" : ""}`}
        >
          Create
        </div>
      </div> */}
      <div className={`bottom-toolbar-item`}>
        <div className={`bottom-toolbar-icon-container`}>
          {dark ? (
            <img
              src={ProfileIcon}
              className={`bottom-toolbar-icon ${
                active == "profile" ? " active" : ""
              }`}
              alt="Profile"
              onClick={goToProfile}
            />
          ) : (
            <img
              src={ProfileIconDark}
              className={`bottom-toolbar-icon ${
                active == "profile" ? " active" : ""
              }`}
              alt="Profile"
              onClick={goToProfile}
            />
          )}
        </div>{" "}
        <div
          className={`bottom-toolbar-item-text${
            active == "profile" ? " active" : ""
          }${dark ? " dark" : ""}`}
        >
          Profile
        </div>
      </div>
    </div>
  );
};

export default BottomToolbar;
